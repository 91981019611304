.blockTop {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.blockTable {
  margin-top: 30px;
}

.blockTop {
  box-shadow: 0px 4px 5px -1px rgb(0 0 0 / 20%);
  .filtros {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    > div {
      width: 100%;
    }
    .cnpj {
      .MuiFormControl-root {
        margin: 0px 0px 10px 0px;
      }
    }

    button.button {
      max-width: 220px;
      height: 56px;
      margin: 3px;
      background: #33b557;

      &:hover {
        filter: brightness(0.9);
        background: #33b557;
        box-shadow: none;
      }

      span {
        height: 56px;
      }
    }
  }
}
.blockTable {
  .TableTop {
    border-radius: 10px 10px 0px 0px;
  }
  .MuiTableCell-root {
    text-align: left !important;
  }
  .MuiButtonBase-root {
    flex-direction: initial;
  }

  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-ncoufy-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #e76b3a;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    border-radius: 10px;
  }
  .VisualizarCNPJ {
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    background: #f6f7f8;
    justify-content: center;
    border-radius: 5px;

    &:hover {
      background: transparent;
    }
  }
  .MuiToolbar-root {
    background: #212b36;
    color: white;

    .MuiButtonBase-root {
      color: white;
    }
  }
  .TablePagination {
    border-radius: 0px 0px 10px 10px;
  }
}
