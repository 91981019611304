.blockTop.Welcome {
  color: white;
  padding: 30px;
  .text--Welcome {
    padding: 20px 0px;
    h2 {
      font-size: 24px;
      font-family: "Nunito", sans-serif;
      font-weight: 800;
      line-height: 37px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      font-family: "Nunito", sans-serif;
      color: #faded3;
      line-height: 25px;
    }
  }
}
.text--version {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  align-items: center;
}

.AreaBannersInitialPage {
  margin-top: 25px;

  .bannerInTheInitialPageDesktop {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      border-radius: 10px;
    }
  }
  .bannerInTheInitialPageMobile {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  @media (max-width: 768px) {
    .bannerInTheInitialPageDesktop img {
      display: none;
    }
  }
  @media (min-width: 769px) {
    .bannerInTheInitialPageMobile {
      display: none;
    }
  }
}
