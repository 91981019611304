div.page-total-area {
  min-height: 100vh;
  background-size: cover;
  padding: 50px 0px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  background-image: url(../../image/FundoImage.png);
  background-color: black;
  align-items: center;

  @media (max-width: 1440px) {
    padding: 50px 0px;
  }

  @media (max-width: 479px) {
    background-image: url(../../image/BannerMob.png);
  }

  .container {
    max-width: 1620px;
    width: 100%;
    padding: 0 70px;

    @media (max-width: 479px) {
      padding: 0 20px;
    }

    .AreaSides {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .left-side {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        @media (max-width: 1024px) {
          order: 1;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
        }

        p {
          color: #ffffff;
          font-size: 24px;
          line-height: 32px;
          text-align: left;
          font-family: "Nunito", sans-serif;
          font-weight: 400;

          @media (max-width: 1024px) {
            text-align: center;
          }
          @media (max-width: 479px) {
            font-size: 15px;
            line-height: 20px;
          }
        }

        h1 {
          color: #ffffff;
          font-size: 35px;
          line-height: 47px;
          text-align: left;
          font-family: "Nunito", sans-serif;
          font-weight: 400;

          @media (max-width: 1024px) {
            text-align: center;
          }
          @media (max-width: 479px) {
            font-size: 22px;
            line-height: 30px;
          }
        }

        .CelphoneArea {
          display: flex;
          gap: 13px;
          align-items: center;
          margin-top: 30px;

          img {
            height: 33px;
          }
          a {
            text-decoration: none;

            p {
              font-size: 20px;
              font-weight: 400;
              line-height: 27px;
              text-align: left;
              font-family: "Nunito", sans-serif;

              @media (max-width: 479px) {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .right-side {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        @media (max-width: 1024px) {
          order: 0;
        }

        header {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .content-right-side {
          margin-top: 45px;

          @media (max-width: 1440px) {
            margin-top: 20px;
          }

          .welcome-area {
            h2 {
              color: #ffffff;
              font-size: 35px;
              line-height: 49px;
              font-family: "Nunito", sans-serif;
              text-align: center;
              font-weight: 400;

              @media (max-width: 1440px) {
                font-size: 30px;
                line-height: 40px;
              }

              @media (max-width: 479px) {
                font-size: 22px;
                line-height: 30px;
              }
            }
            p {
              font-size: 25px;
              color: #b2b2b2;
              line-height: 34px;
              text-align: center;
              font-family: "Nunito", sans-serif;
              font-weight: 400;

              @media (max-width: 1440px) {
                font-size: 20px;
                line-height: 30px;
              }
              @media (max-width: 479px) {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }
          .separator {
            color: #b2b2b2;
            font-size: 25px;
            line-height: 34px;
            font-family: "Nunito", sans-serif;
            text-align: center;
            font-weight: 400;
            display: flex;
            align-items: center;
            margin-top: 45px;
            margin-bottom: 30px;

            @media (max-width: 1440px) {
              margin-top: 20px;
              margin-bottom: 15px;
              font-size: 20px;
              line-height: 30px;
            }

            @media (max-width: 479px) {
              font-size: 15px;
              line-height: 20px;
            }

            &::before {
              content: "";
              flex: 1;
              height: 1px;
              background: linear-gradient(90deg, #131311, #b2b2b2);
              margin-right: 16px;

              @media (max-width: 479px) {
                background: linear-gradient(90deg, transparent, #b2b2b2);
              }
            }

            &::after {
              content: "";
              flex: 1;
              height: 1px;
              background: linear-gradient(90deg, #b2b2b2, #131311);
              margin-left: 16px;

              @media (max-width: 479px) {
                background: linear-gradient(90deg, #b2b2b2, transparent);
              }
            }
          }

          .NoAccount {
            margin-top: 40px;

            @media (max-width: 1440px) {
              margin-top: 20px;
            }
            p {
              color: #b2b2b2;
              font-size: 18px;
              line-height: 24px;
              font-weight: 400;
              font-family: "Nunito", sans-serif;

              @media (max-width: 479px) {
                font-size: 15px;
                line-height: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              @media (max-width: 375px) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }

              .btnCadastre {
                padding: 10px 20px;
                background: transparent;
                margin-left: 20px;
                color: #ffffff;
                border-radius: 0;
                border: 1px solid #ffffff;
                border-radius: 3px;

                transition: 0.3s;

                &:hover {
                  background: #f7403c;
                  border: 1px solid #f7403c;
                }

                @media (max-width: 479px) {
                  padding: 8px 18px;
                  margin-left: 0;
                }

                @media (max-width: 375px) {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
