.GeneralAreaKanbanContent {
  display: flex;
  flex-direction: column;

  .GeneralKanbanHeader {
    display: flex;
    flex: 0 1 auto;
    justify-content: space-between;

    

    .HeaderTextArea {
      display: flex;
      align-items: center;

      h4 {
        color: #4b5563;
        font-size: 2.25rem;
        line-height: 2.5rem;
        font-weight: 700;
      }

      .SetaBaixoIconHeaderTitle {
        padding: 0.25rem;
        margin-left: 1.25rem;
        background-color: #ffffff;
        color: #6b7280;
        border-radius: 9999px;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
          0 10px 10px -5px rgba(0, 0, 0, 0.04);
        height: 30px;
      }
    }

    .CreateNewColumnList {
      display: flex;
      list-style: none;

      button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 0.875rem;

        border-radius: 9999px;
        border-width: 1px;
        border-color: #6b7280;
        border-style: dashed;
      }

      .IconeMaisCreateNewBoard {
        color: #374151;
      }
    }
  }

  .BoardCollumGeneral {
    display: flex;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1.25rem;
    overflow-x: auto;
    padding: 40px 0;

    .MuiTextField-root {
      width: 100%;
    }

    .CollumGeneralArea {
      display: flex;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      border-radius: 0.375rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      width: 300px;
      padding: 0.75rem;

      .GreenColor {
        background-color: #d1fae5;
      }

      .RedLineCollum {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        background-color: rgb(255, 188, 64);
        width: 100%;
        height: 0.25rem;
      }

      .TitleBoardArea {
        display: flex;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .BoardTitle {
          width: 100%;
          color: #484848;
          font-size: 1.2rem;
          line-height: 2rem;
          word-break: break-word;


          > div {
            display: flex;

            button {
              width: 30px;
            }
          }
        }

        .DotsVerticalIcon {
          color: #696969;
          width: 1.25rem;
          height: 1.25rem;
          margin-left: 0.5rem;
        }

        .textEdit {
          border-radius: 0.375rem;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
          max-width: 12.5rem;
          max-height: 2rem;
          resize: none;
        }
      }

      .CardAreaInCollum {
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        align-items: center;

        .chatIcon {
          color: #4d7fff;
          display: flex;
          align-items: center;
        }

        .CardItemClass {
          margin: 0.75rem;
        }


        .addCommentForm {
          padding: 0.5rem;
          margin-top: 0.2rem;
          max-width: 12.6rem;
          resize: none;
          border-radius: 0.375rem;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
          max-height: 2.6rem;
        }

        .EmptyBoard{
          color: #888;
        }
      }

      .AroundTextAreaAddTask {
        display: flex;

        textarea {
          width: 100%;
          border-radius: 0.25rem;
          border-color: #d1d5db;
        }

        .addTaskForm {
          border-radius: 0.375rem;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
          max-width: 16rem;
          resize: none;
        }
      }

      .ButtonAddTask {
        display: flex;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
        margin-left: 0.5rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        justify-content: center;
        align-items: center;

        .PlusCircleIcon {
          color: #6b7280;
          width: 1.25rem;
          height: 1.25rem;
          margin-left: 0.5rem;
          margin-top: 0.2rem;
        }
      }
    }
  }
}
