.Modal {

  .TitleModalInfo {.Modal {

  .TitleModalInfo {
    font-size: 22px;
    font-weight: 700;
    margin-top: 0.5rem;
  }
    
  .close-modal {
    position: absolute;
    top: 9px;
    right: 10px;
    padding: 3px;
    color: rgb(69, 69, 69);
    transition: 0.2s;
  }

  .close-modal:hover {
    background-color: #eeeeee;
  }

  .ChatArea {
    display: flex;
  }

  .divider {
    background-color: rgb(169, 169, 169);
    height: 0.125rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .commentsLabel {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .addCommentArea {
    display: inline-flex;

    .addCommentForm {
      resize: none;
      height: 3rem;
      width: 17.75rem;
      margin-top: 0.5rem;
      margin-left: 0.35rem;
      border-radius: 0.375rem;
    }

    .addCommentButton {
      margin-top: 0.9rem;
      margin-right: 0.9rem;
      margin-left: 0.5rem;
      margin-bottom: 0.9rem;
      padding: 0.3rem;
      color: rgb(69, 69, 69);
      transition: 0.2s;
    }

    .addCommentButton:hover {
      background-color: #eeeeee;
    }
  }
}
    font-size: 22px;
    font-weight: 700;
  }
    
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 3px;
    color: rgb(69, 69, 69);
    transition: 0.2s;
  }

  .close-modal:hover {
    background-color: #eeeeee;
  }

  .ChatArea {
    display: flex;
  }

  .divider {
    background-color: rgb(169, 169, 169);
    height: 0.125rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .commentsLabel {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .commentsList {
    scroll-behavior: smooth;
    max-height: 15rem;
    overflow-y: scroll;
    margin-top: 0.2rem;
  
    .commentsListItem {
      padding: 0.5rem;
      margin: 0.2rem;
      align-items: center;
      border-radius: 0.375rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
        0 2px 4px -1px rgba(0, 0, 0, 0.2);

      .commentEditIcon {
        width: 1.25rem;
        color: rgb(69, 69, 69);
        cursor: pointer;
      }

      .commentDeleteIcon {
        width: 1.3rem;
        color: rgb(255, 50, 50);
        cursor: pointer;
      }

      .commentDateTime {
        font-size: 0.8rem;
        color: rgb(69, 69, 69);
      }
    }
  }

  .addCommentArea {
    display: flex;

    .addCommentForm {
      resize: none;
      width: 21rem;
      margin-top: 0.5rem;
      margin-right: 0.3rem;
      margin-bottom: 0.3rem;
      border-radius: 0.375rem;
    }

    .addCommentButton {
      margin-top: 0.3rem;
      margin-left: 0.3rem;
      padding: 0.5rem;
      color: rgb(69, 69, 69);
      transition: 0.2s;
    }

    .addCommentButton:hover {
      background-color: #eeeeee;
    }
  }

  .editCommentForm {
    padding: 0.5rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);

      .editCommentText {
        resize: none;
        align-items: center;
        border-radius: 0.375rem;
        width: 17rem;
        margin-right: 0.4rem;
      }

      .editCommentIcon {
        margin-top: 0.5rem;
        color: rgb(69, 69, 69);
        transition: 0.2s;
      }

      .editCommentIcon:hover {
        background-color: #eeeeee;
      }
  }
}
