.Popup-Changelog{
    width: 1200px;
    max-width: 100%;
    padding: 10px 20px;

    h2{
        font-size: 20px;
    }

    .ListadeInformacao{
        margin-left: 30px;
    }

    .RecursoEspecifico{
        h3{
            font-size: 18px;
            margin-top:15px;
        }
        li{
            margin-left: 25px;
        }
        
    }
    .CampoExtra{
        margin-top: 10px;
    }
}
