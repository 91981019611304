.AreaApp {
  overflow: hidden;
  background-color: white;
  min-width: 100%;
  height: 100vh;
  min-height: 100vh;

  main {
    padding-top: 4rem;
    padding-left: 10rem;
  }
}
