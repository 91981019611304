#page-dashboard{
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    transition: all 0.5s;

    aside{
        width: 20%;
        max-width: 300px;
        min-width: 250px;
        background-color: #fff;
        border-right: 1px solid #eff2f6;
        .top{
            padding: 20px;
        }

        
        }
        .user-perfil{
            background-color: #F2F3F5;
            display: flex;
            padding: 20px;
            border-radius: 10px;
            margin: 80px 20px 33px;
            align-items: center;
            cursor: pointer;
            overflow: hidden;

            img{
                max-height: 42px;
                max-width: 42px;
                border-radius: 50%;
                margin-right: 15px;
            }


            .user-perfil--name{
                color: #212B36;
                font-size: 16px;
                font-family: 'Nunito', sans-serif;
                font-weight: 700;
                line-height: 22px;
            }
            .user-perfil--position{
                color: #73828E;
                font-size: 14px;
                font-family: 'Nunito', sans-serif;
                font-weight: 400;
                line-height: 19px;
            }

        
    }
    main{
        flex: 1;
        background-color: #EFF2F6;
        overflow: auto;

        .topMenu{
            display: flex;
            height: 100px;
            justify-content: flex-end;
            background-color: #fff;
            padding: 20px 35px;
            align-items: center;

            .topMenu--notification{
                margin-right: 25px;
                svg{
                    height: 42px;
                    width: 42px;
                }
                
            }

            .topMenu--user{
                img{
                    max-height: 45px;
                    max-width: 45px;
                    border-radius: 50%;
                    
                }
            }
        }
        .main-content{
            padding: 30px;
            
        }
    }

    .TableMargin{
        padding-left: 20px;
    }
}

@media(max-width: 780px){
.OpenedMenu::before {
    content: "";
    position: fixed;
    z-index: 2;
    background-color: rgb(0 0 0 / 57%);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1px);
    transition: all 0.3s;
}}