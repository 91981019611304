.AroundCardGeral {
  padding: 0.75rem;
  margin-top: 0;
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 0.375rem;
  box-shadow: inset 0 0 3px #cdcdcd;

  .LabelPriorityCardGreen {
    background-color: #2fde2f;
  }
  
  .LabelPriorityCardOrange {
    background-color: #eac20f;
  }
  
  .LabelPriorityCardRed {
    background-color: #dc2626;
  }
  .LabelPriorityCard {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.25rem;
  }

  .cardMenuIcon {
    align-items: center;
    width: 30px;
    color: #696969;
    padding-left: 0.5rem;
  }

  .TitleCardItemText {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    line-height: 1.5rem;
    word-break: break-word;
    font-weight: 400;
  }

  .InsideAreaCard {
    justify-content: space-between;

    .ListCardBottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      //justify-content: end;

      list-style: none;

      .MocalCall{
        display: flex;
        display: none;
      }
    }
  }
}
