.Input-Customization
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.Input-Customization
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.Input-Customization
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 1px !important;
}
.Input-Customization
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 1px !important;
}

.Input-Customization {
  & > div > fieldset {
    border-color: #fff;

    &:hover {
      border-color: #fff !important;
    }
  }
  input {
    &:hover {
      border-color: #fff !important;
    }
  }
  & > div {
    &:hover {
      border-color: #fff !important;
    }
  }

  label {
    color: #fff !important;
    font-size: 25px;
    line-height: 37px;

    @media (max-width: 479px) {
      font-size: 15px;
      line-height: 24px;
    }

    &[data-shrink="true"] {
      transform: translate(14px, -9px) scale(0.5) !important;

      @media (max-width: 479px) {
        transform: translate(14px, -9px) scale(0.8) !important;
      }
    }
  }

  input {
    color: #b2b2b2;
    font-size: 25px;
    line-height: 34px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    height: 70px;
    padding: 0 16px;
    background-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition-delay: 99999s;
      -webkit-box-shadow: 0 0 0px 1000px;
    }

    @media (max-width: 1440px) {
      font-size: 20px;
      line-height: 30px;
    }

    @media (max-width: 479px) {
      font-size: 15px;
      line-height: 20px;
      height: 55px;
    }
  }
}

button.button {
  height: 50px;
  border-radius: 5px;
  font-weight: 500;
  background: #f7403c;
  color: #fff;
  padding: 0 32px;
  margin-top: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 0;

  transition: filter 0.2s;

  @media (max-width: 1440px) {
    margin-top: 20px;
  }

  img {
    margin-right: 8px;
  }

  &:not(:disabled):hover {
    filter: brightness(0.9);
    background: #f7403c;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    box-shadow: 0px 0px 10px #f7403c;
  }
}
button.button.buttonLogin {
  &.MuiLoadingButton-root {
    background: #f7403c;
  }
}

.RememberPassword {
  color: #b2b2b2;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;

  @media (max-width: 479px) {
    font-size: 15px;
    line-height: 24px;
  }
}

.ButtonEyePassword {
  max-height: 30px;
  width: 38px;
}
