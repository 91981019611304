.ClipButton{
    background: transparent;
    border: 0px;
    cursor: pointer;
}

.blockTop{
    padding: 20px;
    border-radius: 10px;
    background-color: white;


    
}
.blockTable {
    margin-top: 30px;
}

.blocoVisualizar{
    padding: 25px;
    font-family: "Nunito", sans-serif;
        h2{
            padding-bottom: 10px;
        }
}

.redes-sociais--top{
    div{
        display: flex;
        justify-content: space-between;
        span{
            display: flex;

            svg{
                margin: 0px 10px;
                fill: #33B557;
            }
        }
    }
}


.listaVisualizar{
    ul{
        li{
            display:flex;
            font-size: 15px;
            line-height: 55px;
            padding-left: 20px;
            border: 1px solid #E5E8EB;
            border-left: 0px;
            border-right: 0px;
            min-width: 140px;
            flex: 1;
            .tituloLista{
                width:20%;
                color: #212B36;
                font-weight: 400;
                min-width: 130px;
            }
            .conteudoLista{
                display: flex;
                width: 80%;
                
                padding: 15px 0px;

                line-height: 25px;
                font-weight: 500;
                span {
                    margin-right: 5px;
                }
               
                *{
                    display: flex;
                    align-items: center;
                }}



            .divDupla{
                display:flex;
                flex: 1;



                .tituloLista{
                    width: 50%;
                    color: #212B36;
                    font-weight: 400;

                    &.secundario{
                        max-width: 105px;
                    }
                }

                .conteudoLista{
                    padding: 15px 0px;
                    width: 75%;                   
                }
            }

            @media(max-width:1400px){
                &.linhaDupla{
                    flex-direction: column;

                    .tituloLista, .tituloLista.secundario{
                        width: 20%;
                        max-width: 200px;
                    }

                }

            }
        }
        
    }

}
.filialBloco{
    border: 1px solid #E5E8EB;
    padding: 10px 20px;
    .nomeFilial {
        text-align: end;
    }
    .cidadeFilial{
        display: flex;
        justify-content: space-between;

        span{
            font-weight: 700;
        }
    }
    .visualizarFilial{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.semFilial {
    padding: 0px 0px 20px 20px;
    color: black;
    font-size: 17px;
}
.ButtonLista{
    margin: 10px 0px;

}
.VisualisarLista{
    color: black;

}