.MenuSidebar{
    //background-color: red;


    .MenuSidebarList{
        height: auto;
        padding: 0;
        width: 100%;
        list-style: none;


        .row{
            width: 100%;
            height: 50px;
            //background-color: royalblue;
            display: flex;
            align-items: center;
            color: #9CA9B5;
            font-family: 'Nunito', sans-serif;
            font-size: 15px;
            font-weight: 700;
            cursor: pointer;
            transition: all 0.3s;
            &#active{
                background-color: #E76B3A;
                color: #fff;

                .icon{
                    color: #fff;
                }
            }
            
                .linhasMenuList{
                    width: 100%;
                }
            
            
            .BaseRowMenu{
                padding: 8px 35px;
            }
            .icon{
                    color: #687785;

                    
            }
            .iconsubmenu svg{
                height: 13px;
            }

            &:hover{
                background-color: #F2F3F5;
                filter: brightness(0.9);
            }
            
        }
        .dropdown-container{
            display: block; 
        }
        a{
            text-decoration: none;
        }

        .MuiListItemButton-root:hover{
            background: transparent;
        }
        .MuiListItemIcon-root{
            min-width: 38px;
        }
    }
    .menuCustom{
        background: #33B557;
        color: #fff;

        * {
            color: #fff;
        }
        &:hover{
            background-color: #33B557 !important;
            color: #fff;
        }
    }
}

